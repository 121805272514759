import { _ as _baseClone } from '../common/_baseClone-472f2670.js';
import '../common/_Uint8Array-6fb9e774.js';
import '../common/_MapCache-e5a0fcd9.js';
import '../common/_Map-789e2d23.js';
import '../common/isObjectLike-496ccf44.js';
import '../common/_commonjsHelpers-37fa8da4.js';
import '../common/isObject-ab5f8392.js';
import '../common/isArguments-1c726b7b.js';
import '../common/isArray-89a6a4d9.js';
import '../common/_overArg-4068b508.js';
import '../common/isArrayLike-07b3b81b.js';
import '../common/_getPrototype-f0e49a95.js';
import '../common/_baseAssignValue-191f8e00.js';
import '../common/_defineProperty-25185a58.js';
import '../common/_initCloneObject-b408662c.js';
import '../common/_getAllKeys-d1c5689e.js';
import '../common/_arrayPush-ca285057.js';
import '../common/_getTag-095816d1.js';
import '../common/_copyArray-a07edc50.js';
import '../common/_getAllKeysIn-6a39de64.js';

/** Used to compose bitmasks for cloning. */


var CLONE_DEEP_FLAG = 1,
    CLONE_SYMBOLS_FLAG = 4;
/**
 * This method is like `_.clone` except that it recursively clones `value`.
 *
 * @static
 * @memberOf _
 * @since 1.0.0
 * @category Lang
 * @param {*} value The value to recursively clone.
 * @returns {*} Returns the deep cloned value.
 * @see _.clone
 * @example
 *
 * var objects = [{ 'a': 1 }, { 'b': 2 }];
 *
 * var deep = _.cloneDeep(objects);
 * console.log(deep[0] === objects[0]);
 * // => false
 */

function cloneDeep(value) {
  return _baseClone(value, CLONE_DEEP_FLAG | CLONE_SYMBOLS_FLAG);
}

var cloneDeep_1 = cloneDeep;

export default cloneDeep_1;
